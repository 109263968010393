/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/*Fonts*/

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter.ttf');
}

.globalText {
  white-space: pre-wrap;
  padding-left: 2em;
  margin-right: 4em;
  font-size: 12px;
  font-family: Inter;
  letter-spacing: 1px;
}

.globalHeader {
  height: 6em;
  //background: grey;
  text-align: center;
  line-height: 6em;
  font-family: Inter;
  color: yellow;
  transform: translateX(-4px);
  width: 100.2%;

  @media (max-width: 600px) {
    height: 4em;
    line-height: 4em;
  }
}

.globalHeader > .title {
  letter-spacing: 17px;
  text-shadow: 3px 1px 5px black;
  font-size: 40px;
  width: 90%;
  padding-right: 5%;
  float: right;
  font-weight: 700;

  @media (max-width: 600px) {
    letter-spacing: 0px;
    font-size: 16px;
    width: 85%;
    padding-right: 10%;
  }
}

.globalHeader > .titleBack {
  float: left;
  margin-left: 2em;
  font-size: 24px;
  text-shadow: 3px 1px 5px black;

  @media (max-width: 600px) {
    float: none;
    margin-left: 0px;
  }
}

.globalHeader > .titleBack > .backText {
  display: inline-block;
  vertical-align: middle;
}

.globalHeader > .titleBack > ion-icon {
  vertical-align: middle;
  display: inline-block;
  text-shadow: 3px 1px 5px black;
}

.toolbar-background {
  //background: grey !important;
}

ion-toolbar {
  --background: grey !important;
}

.newsHeader {
  text-align: center;
  font-family: 'Inter';
  font-size: 24px;
  letter-spacing: 11px;
  font-weight: 700;
  margin-top: 1em;

  @media (max-width: 600px) {
    letter-spacing: 3px;
  }
}

.newsSubHeader {
  text-align: center;
  font-family: 'Inter';
  font-size: 12px;
  letter-spacing: 5px;
  font-weight: 700;
  margin-top: 2em;

  @media (max-width: 600px) {
    visibility: hidden;
  }
}

ion-header {
  height: 5.5em;
  @media (max-width: 600px) {
    height: 4em;
  }
}

ion-header::after {
  display: none;
}
